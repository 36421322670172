import React, { Suspense, lazy, useState } from 'react';
import { Container } from "react-bootstrap";
import AnimatedPage from "../components/AnimatedPage/AnimatedPage";
import AdminSidebar from '../components/AdminSidebar/AdminSidebar';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import '../styles/AdminCommon.css';

const CollageEdit = lazy(() => import("../components/panel_components/CollageEdit/CollageEdit"));
const AboutEdit = lazy(() => import("../components/panel_components/AboutEdit/AboutEdit"));
const GalleryEdit = lazy(() => import("../components/panel_components/GalleryEdit/GalleryEdit"));
const EditOfferts = lazy(()=>import("../components/panel_components/OfferEdit/EditOfferts"));

function AdminPanel() {
  const [activeComponent, setActiveComponent] = useState('collage');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'collage':
        return <CollageEdit />;
      case 'about':
        return <AboutEdit />;
      case 'gallery':
        return <GalleryEdit />;
        case 'offerts':
        return <EditOfferts />;
      default:
        return <CollageEdit />;
    }
  };

  return (
    <AnimatedPage>
      <div className="admin-layout">
        <AdminSidebar onNavigate={setActiveComponent} active={activeComponent} />
        <div className="admin-content">
          <Container>
            <Suspense fallback={<LoadingSpinner />}>
              {renderComponent()}
            </Suspense>
          </Container>
        </div>
      </div>
    </AnimatedPage>
  );
}

export default AdminPanel;