// src/components/AnimatedPage.js
import { motion } from "framer-motion";

// Bardziej złożony przykład AnimatedPage
const AnimatedPage = ({ children }) => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ y: 20 }}
          animate={{ y: 0 }}
          exit={{ y: -20 }}
          transition={{ 
            duration: 0.7,
            ease: [0.6, -0.05, 0.01, 0.99]
          }}
        >
          {children}
        </motion.div>
      </motion.div>
    );
  };

export default AnimatedPage;


