// pages/Contact.js
import { Container } from "react-bootstrap";
import AnimatedPage from "../components/AnimatedPage/AnimatedPage";
function Contact() {
  return (
    <AnimatedPage>
      <Container>
        <h1>O nas</h1>
      </Container>
    </AnimatedPage>
  );
}

export default Contact;
