import React from "react";
import { Nav } from "react-bootstrap";
import { FaHome, FaImages, FaInfoCircle, FaChevronDown } from "react-icons/fa";
import "./AdminSidebar.css";
import AdminLogo from "../../assets/fut.png";

const AdminSidebar = ({ onNavigate, active }) => {
  return (
    <div className="sidebar-1">
      <Nav className="flex-column">
        <Nav.Item style={{ padding: "1rem 2rem" }}>
          <img className="img-fluid img-fluid-logo" src={AdminLogo} />
        </Nav.Item>
        <div className="lists-header">Twoje sekcje</div>
        <Nav.Link
          className={`sidebar-link ${active === "collage" ? "active" : ""}`}
          onClick={() => onNavigate("collage")}
        >
          <FaHome className="me-2" />
          <span>Kolaż</span>
        </Nav.Link>

        <Nav.Link
          className={`sidebar-link d-flex justify-content-between align-items-center ${
            active === "about" ? "active" : ""
          }`}
          onClick={() => onNavigate("about")}
        >
          <div>
            <FaInfoCircle className="me-2" /> O Mnie
          </div>
        </Nav.Link>

        <Nav.Link
          className={`sidebar-link ${active === "gallery" ? "active" : ""}`}
          onClick={() => onNavigate("gallery")}
        >
          <FaImages className="me-2" /> Albumy
        </Nav.Link>
        <Nav.Link
          className={`sidebar-link ${active === "offerts" ? "active" : ""}`}
          onClick={() => onNavigate("offerts")}
        >
          <FaImages className="me-2" /> Oferty
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default AdminSidebar;
